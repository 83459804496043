import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'

import sentry from 'legacy/utils/sentry'

import { type UserRepository } from 'auth/infra/userRepository'

export type HttpClientConfig = AxiosRequestConfig
export type HttpClientResponse<T> = AxiosResponse<T>
export type HttpClientError<T = any> = AxiosError<T>

export interface HttpClientInterface {
  get<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  post<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  put<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  patch<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  delete<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
}

export type RequestInterceptor = (
  config: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig>
export type ResponseInterceptor = (config: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>

export type HttpClientOptions = Partial<{
  requestInterceptor: RequestInterceptor | null
  responseInterceptor: ResponseInterceptor | null
}>

export class HttpClient implements HttpClientInterface {
  private client: AxiosInstance
  static requestInterceptor: RequestInterceptor
  static responseInterceptor: ResponseInterceptor

  constructor(baseURL?: string, options: HttpClientOptions = {}) {
    this.client = axios.create({
      baseURL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    })

    const {
      requestInterceptor = HttpClient.requestInterceptor,
      responseInterceptor = HttpClient.responseInterceptor,
    } = options

    if (typeof requestInterceptor === 'function') {
      this.client.interceptors.request.use(requestInterceptor)
    }

    if (typeof responseInterceptor === 'function') {
      // using the second param/callback to intercept the error scenario
      // if we need to intercept the success response in the future we will need to change this
      this.client.interceptors.response.use(undefined, responseInterceptor)
    }
  }

  static setup(
    interceptors: { request?: RequestInterceptor; response?: ResponseInterceptor } = {}
  ) {
    if (interceptors.request) {
      HttpClient.requestInterceptor = interceptors.request
    }
    if (interceptors.response) {
      HttpClient.responseInterceptor = interceptors.response
    }
  }

  get<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.get<T>(url, config)
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: HttpClientConfig
  ): Promise<HttpClientResponse<T>> {
    return this.client.post<T>(url, data, config)
  }

  put<T = any>(url: string, data?: any, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.put<T>(url, data, config)
  }

  patch<T = any>(
    url: string,
    data?: any,
    config?: HttpClientConfig
  ): Promise<HttpClientResponse<T>> {
    return this.client.patch<T>(url, data, config)
  }

  delete<T = any>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.delete<T>(url, config)
  }
}

type TokenInterceptorParams = { userRepository: UserRepository; authorizationType?: string }
export function createTokenInterceptor({
  userRepository,
  authorizationType = 'Bearer',
}: TokenInterceptorParams) {
  return function tokenInterceptor(config: HttpClientConfig) {
    try {
      const token = userRepository.getLocalToken()

      if (token && config?.headers) {
        config.headers['Authorization'] = `${authorizationType} ${token}`
      }
    } catch (error) {
      sentry.log(new Error('Failed to get user token', { cause: error }))
    }

    return config
  }
}
