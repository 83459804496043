import { type StorageInterface } from 'core/infra/storage/StorageInterface'

import { TOKEN_STORAGE_KEY } from '../domain/User'

type UserRepositoryDeps = {
  storage: StorageInterface
}

export interface UserRepository {
  getLocalToken(): string | undefined
}

export function createUserRepository({ storage }: UserRepositoryDeps): UserRepository {
  function getLocalToken() {
    return storage.get<string>(TOKEN_STORAGE_KEY)
  }

  return { getLocalToken }
}
