export const TOKEN_STORAGE_KEY = 'loadSmart.user-token'
export const SG_STORAGE_TOKEN_KEY = 'shipperguide-user-token'

export const AGNOSTIC_STRONG_AUTH_TOKEN_KEY = 'agnostic-strong-token'

export function isEmailValid(email: string): boolean {
  // Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  return emailRegex.test(email)
}

export function isPasswordValid(password: string, passwordConfirmation: string) {
  const trimmedPassword = password.trim()
  const trimmedConfirmation = passwordConfirmation.trim()
  const isPasswordEmpty = !trimmedPassword || !trimmedConfirmation

  if (isPasswordEmpty) return false

  const MIN_LENGTH = 8
  const hasLength = trimmedPassword.length >= MIN_LENGTH && trimmedConfirmation.length >= MIN_LENGTH

  const isConfirmationEqual = trimmedPassword === trimmedConfirmation

  return hasLength && isConfirmationEqual
}
