import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

import { user } from 'legacy/utils/user'

import { LoginDialog } from './LoginDialog'
import { SignUpDialog } from './SignUpDialog'

function hasLoginQueryParam(search: string): boolean {
  const { login } = queryString.parse(search)
  return login === 'true'
}

function hasSignupQueryParam(search: string): boolean {
  const { signup } = queryString.parse(search)
  return signup === 'true'
}

export function AuthDialogs() {
  const isUserLoggedIn = user.isLoggedIn()
  const { search, pathname } = useLocation()
  const { replace } = useHistory()
  const queryParams = queryString.parse(search)

  const hasLogin = hasLoginQueryParam(search)
  const hasSignup = hasSignupQueryParam(search)

  const shouldDisplayLoginDialog = hasLogin && !isUserLoggedIn
  const shouldDisplaySignupDialog = hasSignup && !isUserLoggedIn
  const shouldHideAuthDialogs = pathname.includes('/signup') || pathname.includes('/login')

  function updateQuery({ login, signup }: { login?: boolean; signup?: boolean }) {
    const newParams = {
      ...queryParams,
      login,
      signup,
    }

    replace({ search: queryString.stringify(newParams) })
  }

  function removeLoginQuery() {
    updateQuery({ login: undefined })
  }

  function removeSignupQuery() {
    updateQuery({ signup: undefined })
  }

  function addSignupQuery() {
    updateQuery({ signup: true })
  }

  function addLoginQuery() {
    updateQuery({ login: true })
  }

  if (shouldHideAuthDialogs) {
    return null
  }

  if (shouldDisplayLoginDialog) {
    return (
      <LoginDialog
        onCancel={removeLoginQuery}
        onComplete={removeLoginQuery}
        onSignup={addSignupQuery}
      />
    )
  }

  if (shouldDisplaySignupDialog) {
    return (
      <SignUpDialog
        onCancel={removeSignupQuery}
        onComplete={removeSignupQuery}
        onLogin={addLoginQuery}
        context={queryParams.context as string}
      />
    )
  }

  return null
}
